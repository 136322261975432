<template>
  <form
    ref="cardlinkform"
    accept-charset="UTF-8"
    action="https://eurocommerce-test.cardlink.gr/vpos/shophandlermpi"
    method="POST"
    style="display: none"
  >
    <input v-model="cardlinkParams.version" name="version" type="hidden" />
    <input v-model="cardlinkParams.mid" name="mid" type="hidden" />
    <input v-model="cardlinkParams.lang" name="lang" type="hidden" />
    <!-- <input name="deviceCategory" type="hidden" value="" > -->
    <input v-model="cardlinkParams.orderid" name="orderid" type="hidden" />
    <input v-model="cardlinkParams.orderDesc" name="orderDesc" type="hidden" />
    <input v-model="cardlinkParams.orderAmount" name="orderAmount" type="hidden" />
    <input v-model="cardlinkParams.currency" name="currency" type="hidden" />
    <input v-model="cardlinkParams.payerEmail" name="payerEmail" type="hidden" />
    <input v-model="cardlinkParams.payerPhone" name="payerPhone" type="hidden" />
    <input v-model="cardlinkParams.billZip" name="billZip" type="hidden" />
    <input v-model="cardlinkParams.billCity" name="billCity" type="hidden" />
    <input v-model="cardlinkParams.billAddress" name="billAddress" type="hidden" />
    <input v-model="cardlinkParams.billCountry" name="billCountry" type="hidden" />
    <!-- <input name="billState" type="hidden" v-model="cardlinkParams.billState" > -->
    <input v-model="cardlinkParams.confirmUrl" name="confirmUrl" type="hidden" />
    <input v-model="cardlinkParams.cancelUrl" name="cancelUrl" type="hidden" />
    <input v-model="cardlinkParams.digest" name="digest" type="hidden" />
  </form>
</template>

<script>
export default {
  data() {
    return {
      cardlinkParams: {},
    }
  },
  computed: {
    planId() {
      return this.$route.params.id
    },
    subscriptionType() {
      return this.$route.params.type
    },
  },
  mounted() {
    this.$store
      .dispatch('app-common/fetchCardlinkParam', { plan: this.planId, type: this.subscriptionType })
      .then(response => {
        this.cardlinkParams = response.data
        this.$nextTick(() => {
          this.$refs.cardlinkform.submit()
        })
      })
  },
}
</script>
